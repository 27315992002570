<template lang='pug'>
About
</template>

<script>
import About from '../components/About.vue';

export default
{
  components:
  {
    About
  }
};
</script>